import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
  turnoId: 0,
  areaId: 0,
  area: '',
  servicioId: 0,
  servicio: '',
  tratamientoId: 0,
  tratamiento: '',
  tratamientoDuracion: 0,
  tratamientoPrecioLista: 0,
  tratamientoPrecioOferta: 0,

  salaId: 0,
  profesionalId: 0,
  fechaHora: '',
  fechaHoraFin: '',

  rowVersion: null,

  actualizandoTurno: false,
  repitiendoTurno: false
};

const mutations = {
  updateField,

  'INICIALIZAR' (state) {
    state.turnoId = 0;
    state.areaId = 0;
    state.area = '';
    state.servicioId = 0;
    state.servicio = '';
    state.tratamientoId = 0;
    state.tratamiento = '';
    state.tratamientoDuracion = 0;
    state.tratamientoPrecioLista = 0;
    state.tratamientoPrecioOferta = 0;
    state.salaId = 0;
    state.profesionalId = 0;
    state.fechaHora = '';
    state.fechaHoraFin = '';
    state.rowVersion = null;

    state.actualizandoTurno = false;
    state.repitiendoTurno = false;
  },
  'SET_AREA' (state, area) {
    state.turnoId = 0;
    state.areaId = area.id;
    state.area = area.nombre.trim();

    state.servicioId = 0;
    state.servicio = '';
    state.tratamientoId = 0;
    state.tratamiento = '';
    state.tratamientoDuracion = 0;
    state.tratamientoPrecioLista = 0;
    state.tratamientoPrecioOferta = 0;
    state.salaId = 0;
    state.profesionalId = 0;
    state.fechaHora = '';
    state.fechaHoraFin = '';
    state.rowVersion = null;

    state.actualizandoTurno = false;
    state.repitiendoTurno = false;
  },
  'SET_SERVICIO' (state, servicio) {
    state.servicioId = servicio.id;
    state.servicio = servicio.nombre.trim();

    state.tratamientoId = 0;
    state.tratamiento = '';
    state.tratamientoDuracion = 0;
    state.tratamientoPrecioLista = 0;
    state.tratamientoPrecioOferta = 0;
    state.salaId = 0;
    state.profesionalId = 0;
    state.fechaHora = '';
    state.fechaHoraFin = '';
  },
  'SET_TRATAMIENTO' (state, tratamiento) {
    state.tratamientoId = tratamiento.id;
    state.tratamiento = tratamiento.nombre.trim();
    state.tratamientoDuracion = tratamiento.duracionTurno;
    state.tratamientoPrecioLista = tratamiento.precioLista;
    state.tratamientoPrecioOferta = tratamiento.precioOferta;

    state.salaId = 0;
    state.profesionalId = 0;
    state.fechaHora = '';
    state.fechaHoraFin = '';
  },
  'SET_TURNO' (state, turno) {
    // console.log(state.tratamientoDuracion);
    state.salaId = turno.salaId;
    state.profesionalId = turno.profesionalId;
    state.fechaHora = turno.fechaHora;
    state.fechaHoraFin = helpers.methods.addMinutes(turno.fechaHora, parseInt(state.tratamientoDuracion));
    // state.fechaHoraFin = moment(turno.fechaHora).add(parseInt(state.tratamientoDuracion), 'minutes');
  },

  'SET_TURNO_PACIENTE' (state, params) {
    state.turnoId = params.turnoId;
    state.areaId= params.turno.areaId;
    state.area = params.turno.area.trim();
    state.servicioId = params.turno.servicioId;
    state.servicio = params.turno.servicio.trim();
    state.tratamientoId = params.turno.tratamientoId;
    state.tratamiento = params.turno.tratamiento.trim();
    state.tratamientoDuracion = params.turno.duracionTurno;
    state.tratamientoPrecioLista = params.turno.precioLista;
    state.tratamientoPrecioOferta = params.turno.precioOferta;
    state.fechaHora = params.turno.fechaHoraTurno;
    state.rowVersion = params.turno.rowVersion;

    state.salaId = 0;
    state.profesionalId = 0;
    state.fechaHoraFin = '';
    state.actualizandoTurno = true;
  },
  'SET_TURNO_REPETIR' (state, turno) {
    state.turnoId = 0;
    state.areaId= turno.areaId;
    state.area = turno.area.trim();
    state.servicioId = turno.servicioId;
    state.servicio = turno.servicio.trim();
    state.tratamientoId = turno.tratamientoId;
    state.tratamiento = turno.tratamiento.trim();
    state.tratamientoDuracion = turno.duracionTurno;
    state.tratamientoPrecioLista = turno.precioLista;
    state.tratamientoPrecioOferta = turno.precioOferta;
    
    state.salaId = 0;
    state.profesionalId = 0;
    state.fechaHora = '';
    state.fechaHoraFin = '';
    state.rowVersion = null;
    state.repitiendoTurno = true;
  },
};

const actions = {
  inicializar ( {commit} ) {
    return new Promise((resolve, reject) => {
      commit('INICIALIZAR');
      resolve();
    })
  },
  setArea ( {commit}, area ) {
    return new Promise((resolve, reject) => {
      commit('SET_AREA', area);
      resolve();
    })
  },
  setServicio ( {commit}, servicio ) {
    return new Promise((resolve, reject) => {
      commit('SET_SERVICIO', servicio);
      resolve();
    })
  },
  setTratamiento ( {commit}, tratamiento ) {
    return new Promise((resolve, reject) => {
      commit('SET_TRATAMIENTO', tratamiento);
      resolve();
    })
  },
  setTurno ( {commit}, turno ) {
    return new Promise((resolve, reject) => {
      commit('SET_TURNO', turno);
      resolve();
    })
  },
  getTurno ( {commit}, turnoId ) {
    return new Promise((resolve, reject) => {
      commit('INICIALIZAR')

      let getTurno = false;

      if (turnoId) {
        if (turnoId != 0) {
          getTurno = true;
        }
      }

      if (getTurno) {
        apiAxios.get('turnos/paciente/' + turnoId + '/abm')
        .then(res => {
            let params = {
              turnoId: turnoId,
              turno: res.data
            }
            commit('SET_TURNO_PACIENTE', params)
            resolve();
        })
        .catch(error => reject(helpers.methods.getErrorMessage(error)));
      } else {
        resolve();
      }
    })
  },
  getTurnoRepetir ( {commit}, turnoId ) {
    return new Promise((resolve, reject) => {
      commit('INICIALIZAR')

      let getTurno = false;

      if (turnoId) {
        if (turnoId != 0) {
          getTurno = true;
        }
      }

      if (getTurno) {
        apiAxios.get('turnos/paciente/' + turnoId + '/reagendar')
        .then(res => {
            commit('SET_TURNO_REPETIR', res.data)
            resolve();
        })
        .catch(error => reject(helpers.methods.getErrorMessage(error)));
      } else {
        resolve();
      }
    })
  },
  confirmarTurno ( { state, commit } ) {
    return new Promise((resolve, reject) => {
      let url = '';
      let turno;

      if (state.turnoId != 0) {
        url = 'turnos/update/' + state.turnoId;
        turno = {
          // fechaHoraTurno: state.fechaHora,
          fechaHoraTurno: moment(state.fechaHora).format('YYYY-MM-DD HH:mm'),
          salaId: state.salaId,
          rowVersion: state.rowVersion
        }
      } else {
        url = 'turnos/add';
        turno = {
          // fechaHoraTurno: state.fechaHora,
          fechaHoraTurno: moment(state.fechaHora).format('YYYY-MM-DD HH:mm'),
          salaId: state.salaId,
          profesionalId: state.profesionalId,
          tratamientoId: state.tratamientoId
        }
      }

      apiAxios.post(url, turno)
        .then(() => resolve())
        .catch(error => reject(helpers.methods.getErrorMessage(error)));
    })
  },
};
const getters = {
  getField,

  turno (state) {
    return {
      id: state.turnoId,
      fechaHora: state.fechaHora
    }
  },
  areaId (state) {
    return state.areaId;
  },
  area (state) {
    return state.area;
  },
  servicioId (state) {
    return state.servicioId;
  },
  servicio (state) {
    return state.servicio;
  },
  tratamientoId (state) {
    return state.tratamientoId;
  },
  tratamiento (state) {
    return state.tratamiento;
  },
  actualizandoTurno (state) {
    return state.actualizandoTurno;
  },
  repitiendoTurno (state) {
    return state.repitiendoTurno;
  }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}