import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';
import moment from 'moment';

const state = {
    proximosTurnos: [],
    ultimosTurnos: [],
};

const mutations = {
    updateField,

    'INICIALIZAR_PROXIMOS_TURNOS'(state) {
        state.proximosTurnos = [];
    },
    'SET_PROXIMOS_TURNOS'(state, proximosTurnos) {
        state.proximosTurnos = proximosTurnos;
    },
    'INICIALIZAR_ULTIMOS_TURNOS'(state) {
        state.ultimosTurnos = [];
    },
    'SET_ULTIMOS_TURNOS'(state, ultimosTurnos) {
        state.ultimosTurnos = ultimosTurnos.map(turno => ({
            ...turno,
        }));
    },
};

const actions = {
    getProximosTurnos: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_PROXIMOS_TURNOS');

            apiAxios.get('turnos/paciente/proximos')
                .then(res => {
                    commit('SET_PROXIMOS_TURNOS', res.data)
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    getUltimosTurnos: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_ULTIMOS_TURNOS');

            apiAxios.get('turnos/paciente/ultimos')
                .then(res => {
                    commit('SET_ULTIMOS_TURNOS', res.data)
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    cancelarTurno ( {}, turnoId ) {
        return new Promise((resolve, reject) => {
            apiAxios.post('turnos/' + turnoId + '/delete')
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
};

const getters = {
    getField,

    proximosTurnos(state) {
        return state.proximosTurnos;
    },

    ultimosTurnos(state) {
        return state.ultimosTurnos;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}