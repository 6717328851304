export default {
    methods: {
        inputFormatCadena(value, event) {
            // return value.toUpperCase()
            return value.trim();
        },
        inputFormatNumero(value, event) {
            var number = parseInt(value)
            if (!number)
              number = 0
            return number
        },        
        inputFormatImporte(value, event) {
            var number = parseFloat(value)
            if (!number)
              number = 0
            return number
        }        
    },
};