import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    run: '',
    apellido: '',
    apellido2: '',
    nombre: '',
    email: '',
    celular: '',
};

const mutations = {
    updateField,

    'INICIALIZAR' (state) {
        state.run = '';
        state.apellido = '';
        state.apellido2 = '';
        state.nombre = '';
        state.email = '';
        state.celular = '';
    },
    'SET_DATOS' (state, paciente) {
        state.run = paciente.run.trim();
        state.apellido = paciente.apellido.trim();
        state.apellido2 = paciente.apellido2.trim();
        state.nombre = paciente.nombre.trim();
        state.email = paciente.email.trim();
        state.celular = paciente.celular.trim();
    },
};

const getters = {
    getField,

    celular(state) {
        return state.celular;
    }
};

const actions = {
    getDatosPersonales ( { commit } ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR');

            apiAxios.get('pacientes/datos-personales' ) 
            .then(res => {
                commit('SET_DATOS', res.data);
                resolve();
            })
            .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })            
    },

    registrarCuenta ( {}, paciente ) {
        return new Promise((resolve, reject) => {
            apiAxios.post('pacientes/registro', paciente)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    actualizarDatos ( {}, paciente ) {
        return new Promise((resolve, reject) => {
            apiAxios.post('pacientes/update-datos', paciente)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    actualizarPassword ( {}, credenciales ) {
        return new Promise((resolve, reject) => { 
          apiAxios.post('pacientes/reset-password', credenciales)
            .then(() => resolve())
            .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },    
    // forgotPassword ( {}, email ) {
    //     return new Promise((resolve, reject) => { 
    //     apiAxios.post('pacientes/forgot', email)
    //         .then(() => resolve())
    //         .catch(error => reject(helpers.methods.getErrorMessage(error)));
    //     })
    // },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}