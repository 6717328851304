import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    list: [],
    selectList: []
};

const mutations = {
    updateField,

    'INICIALIZAR_LIST'(state) {
        state.list = [];
        state.selectList = [];
    },
    'SET_LIST'(state, list) {
        state.list = list.sort();
        state.selectList = list.map(tratamiento => tratamiento.nombre.trim());
    },
};

const actions = {
    getList: ({commit}, servicioId) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_LIST');

            let getTratamientos = false;
            
            if (servicioId) {
                if (servicioId != 0) {
                    getTratamientos = true;
                }
            }

            if (getTratamientos) {
                let queryString = '?servicioId=' + servicioId;

                apiAxios.get('tratamientos/list' + queryString)
                    .then(res => {
                        commit('SET_LIST', res.data)
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject('Se produjo un error al recuperar la información del servicio seleccionado')
            }
        })
    },
};

const getters = {
    getField,

    list(state) {
        return state.list;
    },
    selectList(state) {
        return state.selectList;
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}