import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    fechaDesde: null,
    fechaHasta: null,

    turnos: [],
    turnosDisplay: [],
    diasNoLaborables: [],
    horario: {},
    turnoSeleccionado: {},

    duracionTurno: 30
};

const mutations = {
    updateField,

    'INICIALIZAR_TURNOS' (state) {
        let fechaDesde = helpers.methods.addDays(new Date(), (-1 * (new Date().getDay() - 1)));
        let anioDesde = fechaDesde.getFullYear();
        let mesDesde = fechaDesde.getMonth();
        let diaDesde = fechaDesde.getDate();
        state.fechaDesde = new Date(anioDesde, mesDesde, diaDesde);
        
        let fechaHasta = helpers.methods.addDays(new Date(), (6 - (new Date().getDay())));
        let anioHasta = fechaHasta.getFullYear();
        let mesHasta = fechaHasta.getMonth();
        let diaHasta = fechaHasta.getDate();
        state.fechaHasta = new Date(anioHasta, mesHasta, diaHasta);

        state.turnos = [];
        state.turnosDisplay = [];
        state.horario = {}; 
        state.turnoSeleccionado = {}; 
        state.diasNoLaborables = [];
    },
    'SET_TURNOS' (state, agenda) {
        state.turnos = agenda.turnos;
        state.horario = agenda.horario;
        state.turnoSeleccionado = agenda.turnoSeleccionado;
        state.diasNoLaborables = agenda.diasNoLaborables;

        getTurnosPeriodo(state);
    },
    'SET_SEMANA' (state, previa) {
        if ((/true/i).test(previa)) {
            state.fechaDesde = helpers.methods.addDays(state.fechaDesde, -7);
            state.fechaHasta = helpers.methods.addDays(state.fechaHasta, -7);
        } else {
            state.fechaDesde = helpers.methods.addDays(state.fechaDesde, 7);
            state.fechaHasta = helpers.methods.addDays(state.fechaHasta, 7);
        }
    },
};

function getTurnosPeriodo (state) {
    state.turnosDisplay = [];

    /* Validamos los dias no laborables */
    let lunes = state.fechaDesde;
    let martes = helpers.methods.addDays(state.fechaDesde, 1);
    let miercoles = helpers.methods.addDays(state.fechaDesde, 2);
    let jueves = helpers.methods.addDays(state.fechaDesde, 3);
    let viernes = helpers.methods.addDays(state.fechaDesde, 4);
    let sabado = helpers.methods.addDays(state.fechaDesde, 5);

    /* Buscamos la hora inicial en el horario habilitado */
    let fechaHoraInicio = state.horario.lunesHoraInicio;
    let fechaHoraFin = state.horario.lunesHoraFin;

    if (moment(fechaHoraInicio).isAfter(state.horario.martesHoraInicio)) {
        fechaHoraInicio = state.horario.martesHoraInicio;
    }
    if (moment(fechaHoraInicio).isAfter(state.horario.miercolesHoraInicio)) {
        fechaHoraInicio = state.horario.miercolesHoraInicio;
    }
    if (moment(fechaHoraInicio).isAfter(state.horario.juevesHoraInicio)) {
        fechaHoraInicio = state.horario.juevesHoraInicio;
    }
    if (moment(fechaHoraInicio).isAfter(state.horario.viernesHoraInicio)) {
        fechaHoraInicio = state.horario.viernesHoraInicio;
    }
    if (moment(fechaHoraInicio).isAfter(state.horario.sabadoHoraInicio)) {
        fechaHoraInicio = state.horario.sabadoHoraInicio;
    }

    if (moment(fechaHoraFin).isBefore(state.horario.martesHoraFin)) {
        fechaHoraFin = state.horario.martesHoraFin;
    }
    if (moment(fechaHoraFin).isBefore(state.horario.miercolesHoraFin)) {
        fechaHoraFin = state.horario.miercolesHoraFin;
    }
    if (moment(fechaHoraFin).isBefore(state.horario.juevesHoraFin)) {
        fechaHoraFin = state.horario.juevesHoraFin;
    }
    if (moment(fechaHoraFin).isBefore(state.horario.viernesHoraFin)) {
        fechaHoraFin = state.horario.viernesHoraFin;
    }
    if (moment(fechaHoraFin).isBefore(state.horario.sabadoHoraFin)) {
        fechaHoraFin = state.horario.sabadoHoraFin;
    }

    let diferenciaMinutos = Math.round((new Date(fechaHoraFin).getTime() - new Date(fechaHoraInicio).getTime()) / 60000);
    let cantidadTurnos = parseInt(diferenciaMinutos / state.duracionTurno);
    let inicioHora = new Date(fechaHoraInicio).getHours();
    let inicioMinutos = new Date(fechaHoraInicio).getMinutes();

    let horaInicio = new Date(1900, 0, 1, inicioHora, inicioMinutos);

    let lunesSelected = false;
    let martesSelected = false;
    let miercolesSelected = false;
    let juevesSelected = false;
    let viernesSelected = false;
    let sabadoSelected = false;

    // if (state.turnoSeleccionado) {
    //     console.log(new Date(state.turnoSeleccionado.fechaHora).getDate());

    //     let fechaHoraTurno = new Date(state.turnoSeleccionado.fechaHora);
    //     let anioTurno = fechaHoraTurno.getFullYear();
    //     let mesTurno = fechaHoraTurno.getMonth();
    //     let diaTurno = fechaHoraTurno.getDate();
    //     let fechaTurno = new Date(anioTurno, mesTurno, diaTurno);

    //     /* Dias de diferencia */
    //     let dias = parseInt((fechaTurno - state.fechaDesde) / (1000 * 60 * 60 * 24), 10);

    //     switch (dias) {
    //     // switch (moment(state.turnoSeleccionado.fechaHora).diff(state.fechaDesde, 'days')) {
    //         case 0:
    //             lunesSelected = true;
    //             break;
    //         case 1:
    //             martesSelected = true;
    //             break;
    //         case 2:
    //             miercolesSelected = true;
    //             break;
    //         case 3:
    //             juevesSelected = true;
    //             break;
    //         case 4:
    //             viernesSelected = true;
    //             break;
    //         case 5:
    //             sabadoSelected = true;
    //             break;
    //     }
    // }

    for (let index = 0; index < cantidadTurnos; index++) {
        let diaSemanaSelected = new Date(state.turnoSeleccionado.fechaHora).getDay();
        let turnoSelected = false;
        if (state.turnoSeleccionado) {
            if (moment(new Date(state.turnoSeleccionado.fechaHora)).format('HH:mm') == moment(horaInicio).add(index * state.duracionTurno,'minutes').format('HH:mm')) {
                turnoSelected = true;
            }
        }
        
        let horaTurno = helpers.methods.addMinutes(horaInicio, index * state.duracionTurno);

        state.turnosDisplay.push({
            key: horaTurno.valueOf(),

            horaTurno,
            horaDisplay: moment(horaTurno).format('HH:mm'),
            ultimoTurno: index == cantidadTurnos - 1? true : false,
            
            lunesDisponible: true,
            lunesTurnoPropio: false,
            lunesTurnoPropioOtro: false,
            lunesSalaId: 0,
            lunesProfesionalId: 0,
            lunesHabilitado: turnoHabilitado(lunes, horaTurno, 
                state.horario.lunesHoraInicio, state.horario.lunesHoraFin) & !state.lunesNoLaborable,
            lunesSelected: turnoSelected & diaSemanaSelected == 1,
            // lunesSelected: turnoSelected & lunesSelected,  /* false, */
            
            martesDisponible: true,
            martesTurnoPropio: false,
            martesTurnoPropioOtro: false,
            martesSalaId: 0,
            martesProfesionalId: 0,
            martesHabilitado: turnoHabilitado(martes, horaTurno, 
                state.horario.martesHoraInicio, state.horario.martesHoraFin) & !state.martesNoLaborable,
            martesSelected: turnoSelected & diaSemanaSelected == 2,
            // martesSelected: turnoSelected & martesSelected,  /* false, */
            
            miercolesDisponible: true,
            miercolesTurnoPropio: false,
            miercolesTurnoPropioOtro: false,
            miercolesSalaId: 0,
            miercolesProfesionalId: 0,
            miercolesHabilitado: turnoHabilitado(miercoles, horaTurno, 
                state.horario.miercolesHoraInicio, state.horario.miercolesHoraFin) & !state.miercolesNoLaborable,
            miercolesSelected: turnoSelected & diaSemanaSelected == 3,
            // miercolesSelected: turnoSelected & miercolesSelected,  /* false, */
            
            juevesDisponible: true,
            juevesTurnoPropio: false,
            juevesTurnoPropioOtro: false,
            juevesSalaId: 0,
            juevesProfesionalId: 0,
            juevesHabilitado: turnoHabilitado(jueves, horaTurno, 
                state.horario.juevesHoraInicio, state.horario.juevesHoraFin) & !state.juevesNoLaborable,
            juevesSelected: turnoSelected & diaSemanaSelected == 4,
            // juevesSelected: turnoSelected & juevesSelected,  /* false, */
            
            viernesDisponible: true,
            viernesTurnoPropio: false,
            viernesTurnoPropioOtro: false,
            viernesSalaId: 0,
            viernesProfesionalId: 0,
            viernesHabilitado: turnoHabilitado(viernes, horaTurno, 
                state.horario.viernesHoraInicio, state.horario.viernesHoraFin) & !state.viernesNoLaborable,
            viernesSelected: turnoSelected & diaSemanaSelected == 5,
            // viernesSelected: turnoSelected & viernesSelected,  /* false, */

            sabadoDisponible: true,
            sabadoTurnoPropio: false,
            sabadoTurnoPropioOtro: false,
            sabadoSalaId: 0,
            sabadoProfesionalId: 0,
            sabadoHabilitado: turnoHabilitado(sabado, horaTurno, 
                state.horario.sabadoHoraInicio, state.horario.sabadoHoraFin) & !state.sabadoNoLaborable,
            sabadoSelected: turnoSelected & diaSemanaSelected == 6,
            // sabadoSelected: turnoSelected & sabadoSelected,  /* false, */

            readOnly: true,
            _rowVariant: ''
        });
    }

    state.turnos.forEach(turno => {
        let fechaHoraTurno = new Date(turno.fechaHoraTurno);
        let anioTurno = fechaHoraTurno.getFullYear();
        let mesTurno = fechaHoraTurno.getMonth();
        let diaTurno = fechaHoraTurno.getDate();
        let fechaTurno = new Date(anioTurno, mesTurno, diaTurno);

        /* Dias de diferencia */
        let dias = parseInt((fechaTurno - state.fechaDesde) / (1000 * 60 * 60 * 24), 10);

        let turnoSelected = false;
        if (state.turnoSeleccionado) {
            if ((/true/i).test(turno.turnoPropio)) {
                if (moment(turno.fechaHoraTurno).format('YYYY-MM-DD HH:mm') == moment(state.turnoSeleccionado.fechaHora).format('YYYY-MM-DD HH:mm')) {
                    turnoSelected = true;
                    // console.log('selected', turno, state.turnoSeleccionado);
                }
            }
        }

        let turnoAgenda = state.turnosDisplay.find(t => moment(t.horaTurno).format('HH:mm') == moment(turno.fechaHoraTurno).format('HH:mm'));
        if (turnoAgenda) {
            switch (dias) {
                case 0:
                    if (!turnoSelected) {
                        turnoAgenda.lunesDisponible = (/true/i).test(turno.disponible);
                        turnoAgenda.lunesTurnoPropio = (/true/i).test(turno.turnoPropio);
                        turnoAgenda.lunesTurnoPropioOtro = (/true/i).test(turno.turnoPropioOtro);
                    }
                    turnoAgenda.lunesSalaId = turno.salaId;
                    turnoAgenda.lunesProfesionalId = turno.profesionalId;
                    break;
                case 1:
                    if (!turnoSelected) {
                        turnoAgenda.martesDisponible = (/true/i).test(turno.disponible);
                        turnoAgenda.martesTurnoPropio = (/true/i).test(turno.turnoPropio);
                        turnoAgenda.martesTurnoPropioOtro = (/true/i).test(turno.turnoPropioOtro);
                    }
                    turnoAgenda.martesSalaId = turno.salaId;
                    turnoAgenda.martesProfesionalId = turno.profesionalId;
                    break;
                case 2:
                    if (!turnoSelected) {
                        turnoAgenda.miercolesDisponible = (/true/i).test(turno.disponible);
                        turnoAgenda.miercolesTurnoPropio = (/true/i).test(turno.turnoPropio);
                        turnoAgenda.miercolesTurnoPropioOtro = (/true/i).test(turno.turnoPropioOtro);
                    }
                    turnoAgenda.miercolesSalaId = turno.salaId;
                    turnoAgenda.miercolesProfesionalId = turno.profesionalId;
                    break;
                case 3:
                    if (!turnoSelected) {
                        turnoAgenda.juevesDisponible = (/true/i).test(turno.disponible);
                        turnoAgenda.juevesTurnoPropio = (/true/i).test(turno.turnoPropio);
                        turnoAgenda.juevesTurnoPropioOtro = (/true/i).test(turno.turnoPropioOtro);
                    }
                    turnoAgenda.juevesSalaId = turno.salaId;
                    turnoAgenda.juevesProfesionalId = turno.profesionalId;
                    break;
                case 4:
                    if (!turnoSelected) {
                        turnoAgenda.viernesDisponible = (/true/i).test(turno.disponible);
                        turnoAgenda.viernesTurnoPropio = (/true/i).test(turno.turnoPropio);
                        turnoAgenda.viernesTurnoPropioOtro = (/true/i).test(turno.turnoPropioOtro);
                    }
                    turnoAgenda.viernesSalaId = turno.salaId;
                    turnoAgenda.viernesProfesionalId = turno.profesionalId;
                    break;
                case 5:
                    if (!turnoSelected) {
                        turnoAgenda.sabadoDisponible = (/true/i).test(turno.disponible);
                        turnoAgenda.sabadoTurnoPropio = (/true/i).test(turno.turnoPropio);
                        turnoAgenda.sabadoTurnoPropioOtro = (/true/i).test(turno.turnoPropioOtro);
                    }
                    turnoAgenda.sabadoSalaId = turno.salaId;
                    turnoAgenda.sabadoProfesionalId = turno.profesionalId;
                    break;
            }
        }
    });
}

function turnoHabilitado(dia, horaTurno, horaInicio, horaFin) {
    /* Validamos los dias no laborables */
    if (state.diasNoLaborables) {
        if (state.diasNoLaborables.length != 0) {
            let diaNoLaborable = state.diasNoLaborables
                .find(dnl => moment(new Date(dnl.fechaNoLaborable)).format('YYYY-MM-DD') == moment(dia).format('YYYY-MM-DD'));
            if (diaNoLaborable) {
                return false;
            }
        }
    }

    /* Valoramos el dia y hora del turno (que sea posterior a la fecha y hora actual) */
    let diaHora = helpers.methods.addHours(dia, horaTurno.getHours());
    diaHora = helpers.methods.addMinutes(dia, horaTurno.getMinutes());

    if (diaHora.valueOf() < new Date().valueOf()) {
        return false;
    } else {
    }

    /* Validamos la hora del turno segun el horario de inicio y fin del día */
    let horas = horaTurno.getHours();
    let minutos = horaTurno.getMinutes();
    let totalMinutos = minutos + horas * 60;

    let horasInicioDia = new Date(horaInicio).getHours();
    let minutosInicioDia = new Date(horaInicio).getMinutes();
    let totalMinutosInicioDia = minutosInicioDia + horasInicioDia * 60;

    let horasFinDia = new Date(horaFin).getHours();
    let minutosFinDia = new Date(horaFin).getMinutes();
    let totalMinutosFinDia = minutosFinDia + horasFinDia * 60;

    if (totalMinutosInicioDia > totalMinutos || totalMinutosFinDia <= totalMinutos ) {
        return false;
    }

    return true;
}

const getters = {
    getField,

};

const actions = {
    inicializarTurnos ( { commit } ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_TURNOS');
            resolve();
        }, error => {
            reject(error);
        })            
    },
    getAgenda ( { commit, state, rootGetters } ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_TURNOS');
            
            let tratamientoId = 0;
            let turno= null;

            if (rootGetters['turno/tratamientoId']) {
                if (rootGetters['turno/tratamientoId'] != 0) {
                    tratamientoId = rootGetters['turno/tratamientoId'];
                }
            } 
            if (rootGetters['turno/turno']) {
                turno = rootGetters['turno/turno'];
            } 

            if (tratamientoId != 0) {
                let queryString = '?fechaDesde=' + moment(state.fechaDesde).format('YYYY-MM-DD')
                    + '&fechaHasta=' + moment(state.fechaHasta).format('YYYY-MM-DD');
                        
                apiAxios.get('turnos/tratamiento/' + tratamientoId + queryString)
                    .then(res => {
                        res.data.turnoSeleccionado = turno;

                        commit('SET_TURNOS', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        }, error => {
            reject(error);
        })            
    },
    semanaPrevia: ({ commit, state, rootGetters }) => {
        return new Promise((resolve, reject) => {
            commit('SET_SEMANA', Boolean(true));
            
            let tratamientoId = 0;
            let turno= null;

            if (rootGetters['turno/tratamientoId']) {
                if (rootGetters['turno/tratamientoId'] != 0) {
                    tratamientoId = rootGetters['turno/tratamientoId'];
                }
            } 
            if (rootGetters['turno/turno']) {
                turno = rootGetters['turno/turno'];
            } 

            if (tratamientoId != 0) {
                let queryString = '?fechaDesde=' + moment(state.fechaDesde).format('YYYY-MM-DD')
                    + '&fechaHasta=' + moment(state.fechaHasta).format('YYYY-MM-DD');
                        
                apiAxios.get('turnos/tratamiento/' + tratamientoId + queryString)
                    .then(res => {
                        res.data.turnoSeleccionado = turno;

                        commit('SET_TURNOS', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        }, error => {
            reject(error);
        })            
    },
    semanaProxima: ({ commit, state, rootGetters }) => {
        return new Promise((resolve, reject) => {
            commit('SET_SEMANA', Boolean(false));
            
            let tratamientoId = 0;
            let turno= null;

            if (rootGetters['turno/tratamientoId']) {
                if (rootGetters['turno/tratamientoId'] != 0) {
                    tratamientoId = rootGetters['turno/tratamientoId'];
                }
            } 
            if (rootGetters['turno/turno']) {
                turno = rootGetters['turno/turno'];
            } 

            if (tratamientoId != 0) {
                let queryString = '?fechaDesde=' + moment(state.fechaDesde).format('YYYY-MM-DD')
                    + '&fechaHasta=' + moment(state.fechaHasta).format('YYYY-MM-DD');
                        
                apiAxios.get('turnos/tratamiento/' + tratamientoId + queryString)
                    .then(res => {
                        res.data.turnoSeleccionado = turno;

                        commit('SET_TURNOS', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
                } else {
                    resolve();
                }
            }, error => {
            reject(error);
        })            
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}