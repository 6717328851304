import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'

// Containers
const Container = () => import('@/containers/Container')

// Views
/*
const Dashboard = () => import('@/views/Dashboard')
*/

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/Login')
const ResetPassword = () => import('@/views/ResetPassword')

const Home = () => import('@/views/Home')
const Agenda = () => import('@/views/Agenda')
const ConfirmacionTurno = () => import('@/views/ConfirmacionTurno')
const DatosPaciente = () => import('@/views/DatosPaciente')
const DatosPacientePassword = () => import('@/views/DatosPacientePassword')
const DatosPacienteTelefono = () => import('@/views/DatosPacienteTelefono')
const ForgotPassword = () => import('@/views/ForgotPassword')
const HistorialTurnos = () => import('@/views/HistorialTurnos')
const Registro = () => import('@/views/Registro')
const SeleccionServicio = () => import('@/views/SeleccionServicio')
const SeleccionTratamiento = () => import('@/views/SeleccionTratamiento')

Vue.use(Router)

function requireUser (to, from, next) {
  if ( !store.getters['user/loggedIn'] ) {
    store.dispatch('user/tryLogin')
      .then(() => next ())
      .catch(() => next ('/login'));     
  } else {
    next ();
  }
}

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  // mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/inicio',
      name: 'Home',
      meta: { label: 'Inicio'},
      component: Container,
      children: [
        {
          path: 'inicio',
          name: 'Solestic',
          // meta: { label: 'Inicio'},
          component: Home,
          beforeEnter: requireUser
        },
        {
          path: 'agenda',
          name: 'Agenda',
          // meta: { label: 'Inicio'},
          component: Agenda,
          beforeEnter: requireUser
        },
        {
          path: 'datos-personales',
          // meta: { label: 'Usuarios'},
          component: {
            render (c) { return c('router-view') }
          },
          beforeEnter: requireUser,
          children: [
            {
              path: '',
              name: 'DatosPaciente',
              component: DatosPaciente,
              beforeEnter: requireUser
            },
            {
              path: 'telefono',
              name: 'DatosPacienteTelefono',
              component: DatosPacienteTelefono,
              beforeEnter: requireUser
            },
            {
              path: 'password',
              name: 'DatosPacientePassword',
              component: DatosPacientePassword,
              beforeEnter: requireUser
            },
          ]
        },
        {
          path: 'seleccion-servicio',
          name: 'SeleccionServicio',
          // meta: { label: 'Inicio'},
          component: SeleccionServicio,
          beforeEnter: requireUser
        },
        {
          path: 'seleccion-tratamiento',
          name: 'SeleccionTratamiento',
          // meta: { label: 'Inicio'},
          component: SeleccionTratamiento,
          beforeEnter: requireUser
        },
        {
          path: 'confirmacion',
          name: 'ConfirmacionTurno',
          // meta: { label: 'Inicio'},
          component: ConfirmacionTurno,
          beforeEnter: requireUser
        },
        {
          path: 'historial',
          name: 'HistorialTurnos',
          // meta: { label: 'Inicio'},
          component: HistorialTurnos,
          beforeEnter: requireUser
        },
      ]
    },


    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
      ]
    },

    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/registro',
      name: 'Registro',
      component: Registro,
    },
    {
      path: '/forgot',
      name: 'ForgotPassword',
      component: ForgotPassword,
    },
    {
      path: '/reset-pwd',
      name: 'ResetPassword',
      component: ResetPassword
    },
  ]
})
