import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
  avatar: '',
  apellido: '',
  apellido2: '',
  nombre: '',
  email: '',
  tokenString: '',
};

const mutations = {
  'LOGIN'(state, params) {
    state.email = params.user.email;
    state.apellido = params.user.apellido;
    state.apellido2 = params.user.apellido2;
    state.nombre = params.user.nombre;
    state.tokenString = params.tokenString;
  },
  'LOGOUT'(state) {
    state.email = '';
    state.apellido = '';
    state.apellido2 = '';
    state.nombre = '';
    state.tokenString = '';
  },
};

const actions = {
  login ( { commit }, credenciales ) {
    return new Promise((resolve, reject) => { 
      commit('LOGOUT');

      apiAxios.post('auth-pac/login', credenciales)
        .then((res) => {
            commit('LOGIN', res.data);

            const now = new Date();
            const expirationDate = new Date(now.getTime() + 3000000);
            localStorage.setItem('sol-cli-email', res.data.user.email);
            localStorage.setItem('sol-cli-token', res.data.tokenString);
            localStorage.setItem('sol-cli-expirationDate', expirationDate);
            
            resolve();
        })
        .catch(error => reject(helpers.methods.getErrorMessage(error)));
    })
  },
  tryLogin: ({commit}) => {
    return new Promise((resolve, reject) => {
      /* Recuperamos los datos del localStorage */  
      let email = localStorage.getItem('sol-cli-email');
      let tokenString = localStorage.getItem('sol-cli-token');
      let expirationDate = new Date(localStorage.getItem('sol-cli-expirationDate', expirationDate));
      let currentDate = new Date();
      
      /* Validamos datos del usuario y la fecha de expiración */
      if (email && tokenString 
          && expirationDate.getTime() > currentDate.getTime()) {
        
        var credenciales = {
          email: email,
          tokenString: tokenString
        }
        apiAxios.post('auth-pac/auto', credenciales) 
          .then(res => {
            commit('LOGIN', res.data);

            /* Actualizamos los datos del token */
            const now = new Date();
            const expirationDate = new Date(now.getTime() + 3000000);
            localStorage.setItem('sol-cli-token', res.data.tokenString);
            localStorage.setItem('sol-cli-expirationDate', expirationDate);

            resolve();
          })
          .catch(error => reject(helpers.methods.getErrorMessage(error)));
        } else {
          reject();
        }
    })
  },  
  logout: ({commit}) => {
    return new Promise((resolve, reject) => {
      commit('LOGOUT');

      localStorage.removeItem('sol-cli-email');
      localStorage.removeItem('sol-cli-token');
      localStorage.removeItem('sol-cli-expirationDate');

      resolve();
    })
  },

  forgotPassword ( {}, email ) {
    return new Promise((resolve, reject) => {
      let credenciales = {
        email
      } 
      apiAxios.post('auth-pac/forgot', credenciales)
        .then(() => resolve())
        .catch(error => reject(helpers.methods.getErrorMessage(error)));
    })
  },
  resetPassword ( {}, credenciales ) {
    return new Promise((resolve, reject) => { 
      apiAxios.post('auth-pac/reset-password', credenciales)
        .then(() => resolve())
        .catch(error => reject(helpers.methods.getErrorMessage(error)));
    })
  },
};

const getters = {
  loggedIn(state) {
    // console.log(state);
    if (state.email) {
      if (state.email != '') {
        /* Validar password vencido */
        return true;
      }
    }
    return false;
  },
  email (state) {
    return state.email;
  },
  apellido (state) {
    return state.apellido;
  },
  apellido2 (state) {
    return state.apellido2;
  },
  nombre (state) {
    return state.nombre;
  },
  tokenString (state) {
    return state.tokenString;
  },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}