export default {
    methods: {
        getSelected (list, nombre) {
            var selected = {};
            if (nombre) {
              for ( let key in list ){
                const element = list[key];
                if (element.nombre.trim() == nombre.trim() ){
                  selected = element;
                }
              }
            }
            return selected;
        },
        formattedData (item) {
          var value = item.valor;
          switch (item.formato.trim()) {
            case "fecha":
              value = this.$options.filters.fecha(item.valor);
              break;
            case "fechaHora":
              value = this.$options.filters.fechaHora(item.valor);
              break;
            case "fechaHoraEstado":
              value = this.$options.filters.fechaHoraEstado(item.valor);
              break;
            case "periodo":
              value = this.$options.filters.periodo(item.valor);
              break;
            case "cuit":
              value = this.$options.filters.cuit(item.valor);
              break;
            case "porcentaje":
              value = this.$options.filters.numero(item.valor, 2, true);
              break;
            case "moneda":
              value = this.$options.filters.moneda(item.valor);
              break;
          }
          return value;
        },
        camelize(str) {
          return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
            if (+match === 0) return " "; // or if (/\s+/.test(match)) for white spaces
              return index == 0 ? match.toUpperCase() : match.toLowerCase();
          });
        },
        addDays(date, days) {
            var result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        },
        addHours(date, hours) {
          return new Date(date.getTime() + (hours * 60 * 60 * 1000));
        },
        addMinutes(date, minutes) {
          return new Date(date.getTime() + minutes * 60000);
        },
        showSpinnerLogin () {
          let height = 0;
          let offsetTop = 0;
          let width = 0;
          let viewHeight = window.innerHeight;
          if (document.getElementsByClassName("contenedor-login")) {
            if (document.getElementsByClassName("contenedor-login")[0]) {
              height = document.getElementsByClassName("contenedor-login")[0].clientHeight
                + document.getElementsByClassName("contenedor-login")[0].offsetTop;
              offsetTop = document.getElementsByClassName("contenedor-login")[0].offsetTop;
              width = document.getElementsByClassName("contenedor-login")[0].clientWidth;
            }
          }
          if (height < viewHeight) {
            height = viewHeight;
          }
          let params = {
            height,
            offsetTop,
            width
          }

          this.$store.dispatch('spinner/show', params);
        },
        showSpinner () {
          let height = 0;
          let offsetTop = 0;
          let width = 0;
          let viewHeight = window.innerHeight;
          if (document.getElementsByClassName("app-body")) {
            if (document.getElementsByClassName("app-body")[0]) {
              height = document.getElementsByClassName("app-body")[0].clientHeight
                + document.getElementsByClassName("app-body")[0].offsetTop;
              offsetTop = document.getElementsByClassName("app-body")[0].offsetTop;
              width = document.getElementsByClassName("app-body")[0].clientWidth;
            }
          }
          if (height < viewHeight) {
            height = viewHeight;
          }
          let params = {
            height,
            offsetTop,
            width
          }
          // console.log(params);
          // console.log(document.getElementsByClassName("app-body"));
          this.$store.dispatch('spinner/show', params);
        },
        showSpinnerSized (height) {
          let offsetTop = 0;
          let width = 0;
          if (document.getElementsByClassName("app-body")) {
            if (document.getElementsByClassName("app-body")[0]) {
              offsetTop = document.getElementsByClassName("app-body")[0].offsetTop;
              width = document.getElementsByClassName("app-body")[0].clientWidth;
            }
          }
          let params = {
            height,
            offsetTop,
            width
          }

          this.$store.dispatch('spinner/show', params);
        },
        hideSpinner () {
          this.$store.dispatch('spinner/hide');
        },
        getErrorMessage(error) {
          // console.log(error);
          
          let errorMessage = '';
          if (error.response) {
            if (error.response.status == 500) {
                if (error.response.data) {
                  switch (errorMessage = error.response.data.trim().toLowerCase()) {
                    case 'unable to connect to any of the specified mysql hosts.':
                      errorMessage = 'Se produjo un error al intentar conectar con la base de datos.'
                      break;
                  
                    default:
                      errorMessage = error.response.data;
                      break;
                  }
                }
            } else if (error.response.status == 400) {
              if (error.response.data) {
                errorMessage = error.response.data;
  
                if (error.response.data.hasOwnProperty('Message')) {
                  if (error.response.data.Message == 'ModelStateInvalid') {
                    if (error.response.data.Data.validations) {
                      errorMessage = '';
  
                      for (const validationItem in error.response.data.Data.validations) {
                        let validation = error.response.data.Data.validations[validationItem];
  
                        if (validation.errors) {
                            validation.errors.forEach(error => {
                              errorMessage += '\r\n' + error.errorMessage.trim();
                            });
                        }
                      }
                    } 
                  }
                }
              } else {
                errorMessage = error.message;
              }
            } else if (error.response.status == 401) {
              if (error.response.data) {
                errorMessage = error.response.data;
              } else {
                errorMessage = error.message;
              }
            }
          }  
          if (errorMessage == '') {
            if (error.message) {
              switch (error.message.trim().toLowerCase()) {
                case 'network error':
                  errorMessage = 'Se produjo un error al intentar conectar al servidor de la aplicación.'
                  break;
              
                default:
                  errorMessage = error.message;
                  break;
              }
            }
          }
          return errorMessage;
        },
    },
};