import Vue from 'vue';
import Vuex from 'vuex';

import agenda from './modules/agenda';
import areas from './modules/areas';
import paciente from './modules/paciente';
import servicios from './modules/servicios';
import spinner from './modules/spinner';
import tratamientos from './modules/tratamientos';
import turno from './modules/turno';
import turnos from './modules/turnos';
import user from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        agenda,
        areas,
        paciente,
        servicios,
        spinner,
        tratamientos,
        turno,
        turnos,
        user,
    }
});