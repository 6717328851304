const state = {
    spinning: false,
    height: 0,
    offsetTop: 0,
    width: 0,
};

const mutations = {
    'SHOW_SPINNER'(state, params) {
        state.spinning = true;
        state.height = params.height;
        state.offsetTop = params.offsetTop;
        state.width = params.width;
    },
    'HIDE_SPINNER'(state) {
        state.spinning = false;
        state.height = 0;
        state.offsetTop = 0;
        state.width = 0;
    },
};

const getters = {
    spinning (state) {
        return state.spinning;
    },
    height (state) {
        return state.height;
    },
    offsetTop (state) {
        return state.offsetTop;
    },
    width (state) {
        return state.width;
    },
};

const actions = {
    procesando ( { commit, state }, params ) {
        if (state.procesando) {
            commit('HIDE_SPINNER');
        } else {
            commit('SHOW_SPINNER', params);
        }
    },    
    show ( { commit }, params ) {
        commit('SHOW_SPINNER', params);
    },    
    hide ( { commit } ) {
        commit('HIDE_SPINNER');
    },    
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}