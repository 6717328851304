import axios from 'axios';
import store from '../store/store';

const apiAxios = axios.create({
    // baseURL: 'http://localhost:5000/api'
    baseURL: 'https://admin.solestic.cl/api'
});

apiAxios.interceptors.request.use(function(config) {
    const token = store.getters['user/tokenString'];
    if(token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, function(err) {
    return Promise.reject(err);
});

export default apiAxios;